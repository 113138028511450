<template>
  <div class="md-layout md-alignment-center">
    <div class="md-layout-item md-small-hide">
      <img src="@/assets/img/slide.jpg" alt="Logo" class="slide-image" />
    </div>
    <div
      class="
        md-layout-item
        md-size-50
        md-medium-size-50
        md-small-size-70
        md-xsmall-size-100
      "
    >
      <ValidationListener
        @getIsValid="(isValid) => (passwordReset.isFormValid = isValid)"
      >
        <login-card>
          <h3 slot="title" class="title"><br />Reset Password</h3>
          <p slot="subheader" class="md-caption text-center m-0">
            Please reset your password
          </p>
          <div slot="inputs">
            <ValidationHandler
              :name="passwordReset.postData.email"
              rules="required"
              v-slot="{ error }"
            >
              <text-input
                label="Email..."
                placeholder="Enter Email"
                :required="true"
                v-model="passwordReset.postData.email"
                :error="error"
                icon="mail_outline"
                @submit="passwordResetConfirm()"
              />
            </ValidationHandler>
            <ValidationHandler
              :name="passwordReset.postData.password"
              rules="required"
              v-slot="{ error }"
            >
              <text-input
                type="password"
                label="new Password..."
                placeholder="Enter new Password..."
                :required="true"
                v-model="passwordReset.postData.password"
                :error="error"
                icon="lock_outline"
                @submit="passwordResetConfirm()"
              />
            </ValidationHandler>
            <ValidationHandler
              :name="passwordReset.postData.confirmPassword"
              rules="required"
              v-slot="{ error }"
            >
              <text-input
                type="password"
                label="re-enter Password..."
                placeholder="Re-enter Password..."
                :required="true"
                v-model="passwordReset.postData.confirmPassword"
                :error="error"
                icon="lock_outline"
                @submit="passwordResetConfirm()"
              />
            </ValidationHandler>
          </div>
          <md-button slot="footer" @click="cancelReset()">Cancel</md-button>
          <md-button
            slot="footer"
            class="md-primary"
            @click="passwordResetConfirm()"
          >
            Reset
          </md-button>
        </login-card>
      </ValidationListener>
    </div>
  </div>
</template>
<script>
import LoginCard from "../../components/Cards/LoginCard.vue";
export default {
  components: {
    "login-card": LoginCard,
  },
  mounted() {},
  methods: {
    goHome() {
      this.$router.push("/");
    },
  },
  inject: ["passwordReset", "passwordResetConfirm", "cancelReset"],
};
</script>
<style></style>
